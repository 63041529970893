import React, { Component } from 'react';

export class Footer extends Component {
    render() {
        return (
            <footer className="footer mt-auto">
                <hr />
                <p className="d-flex justify-content-center fw-bold">&copy; {new Date().getFullYear()} - Coyote. All rights reserved.&nbsp;
                    <a href="https://coyote.com/terms-privacy/#webterms" target="_blank">Terms of Use</a>&nbsp;
                    <a href="https://coyote.com/terms-privacy/#privacy" target="_blank">Privacy Policy</a>&nbsp;
                </p>
            </footer>
        );
    }
}