import { Document, Page, pdfjs } from 'react-pdf/dist/esm/entry.webpack5';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import React, { useState, useEffect } from 'react';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import { useParams } from "react-router-dom";
import "../stylesheets/rateConStyle.css";
import { Header } from './Header';
import { Footer } from './Footer';
import axios from 'axios';


export function RateConfirmations(props) {

    const [pdfFileData, setPdfFileData] = useState();
    const [numPages, setNumPages] = useState(null);
    const [showError, setShowError] = useState(false);

    const params = useParams();


    const downloadRateConfirmationPdf = (e) => {
        const url = window.URL.createObjectURL(new Blob([pdfFileData]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${params.id}.pdf`);
        document.body.appendChild(link);
        link.click();
    }
     
    let { carrierAgreementWebAppUri } = window.config;
    carrierAgreementWebAppUri = carrierAgreementWebAppUri.concat("api/RateConfirmation/");

    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
    }

    async function populateRateConPdfData() {
        if (params.id !== "") {
            setShowError(false);
            axios({
                method: 'GET',
                url: `${carrierAgreementWebAppUri}${params.id}`,
                responseType: 'arraybuffer',
            })
                .then(response => {
                    setPdfFileData(response.data);
                    setShowError(false);
                })
                .catch((error) => {
                    setShowError(true);
                });
        }
    }

    useEffect(() => {
        /*pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;*/
        pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@2.16.105/build/pdf.worker.min.js`;

        populateRateConPdfData();
    }, []);

    const documentOptions = {
        cMapUrl: 'cmaps/',
        cMapPacked: true,
        standardFontDataUrl: 'standard_fonts/',
    };

    const pageScale = 2.0;

    return (
        <div>
            <Header />
            <div className="m-5">
                {!(params.id) || showError ?
                    <div className="errorDiv">
                        <p className="text-danger fw-bold errorPara">
                            Your token is either invalid or expired. This Rate Confirmation can no longer be viewed. <br />
                            Please contact your Coyote representative if this issue persists or if you believe this is incorrect.
                        </p>
                    </div>
                    :
                    <div>
                        <div className="d-flex justify-content-center mb-3">
                            <button className="btn btn-primary" onClick={downloadRateConfirmationPdf}>Download</button>
                        </div>

                        <Document className="d-flex justify-content-center vstack gap-3"
                            file={{ data: pdfFileData }}
                            onLoadSuccess={onDocumentLoadSuccess}
                            options={documentOptions}>

                            {Array.from(new Array(numPages), (el, index) => (
                                <div key={`pagediv_${index + 1}`}>
                                    <hr key={`separator_${index + 1}`}
                                        className="boldSeparator" />
                                    <Page className="d-flex justify-content-center"
                                        key={`page_${index + 1}`}
                                        pageNumber={index + 1}
                                        scale={pageScale} />
                                </div>
                            ))}

                        </Document>
                    </div>
                }

            </div>
            {!(params.id) ? <></> : <Footer />}

        </div>
    );
}

