import React, { Component } from 'react';

export class Ping extends Component {
    static displayName = Ping.name;

    render() {
        return (
            <div className="text-success">true</div>
        );
    }
}