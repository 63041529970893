import { Route, Routes } from 'react-router-dom';
import React, { Component } from 'react';
import AppRoutes from './AppRoutes';
import './custom.css';
import ConfigLoader from "./ConfigLoader";

export default class App extends Component {
    static displayName = App.name;

    render() {
        return (
            <ConfigLoader
                ready={() => (
                    <div>
                        <Routes>
                            {AppRoutes.map((route, index) => {
                                const { element, ...rest } = route;
                                return <Route key={index} {...rest} element={element} />;
                            })}
                        </Routes>
                    </div>
                )}
            />
        );
    }
}
