import { Home } from "./components/Home";
import { Ping } from "./components/Ping";
import { Nudge } from "./components/Nudge";
import { RateConfirmations } from "./components/RateConfirmations";

const AppRoutes = [
    {
        index: true,
        element: <Home />
    },
    {
        path: '/RateConfirmations/:id',
        element: <RateConfirmations />
    },
    {
        path: '/RateConfirmations',
        element: <RateConfirmations />
    },
    {
        path: '/Ping',
        element: <Ping />
    },
    {
        path: '/Nudge',
        element: <Nudge />
    }
];

export default AppRoutes;
