import React, { Component } from 'react';
import coyoteFavicon from "../resources/coyote_favicon.png";
import "../stylesheets/headerStyle.css";

export class Header extends Component {
    render() {
        return (
            <div className="border-bottom box-shadow p-2 mb-3 headerDiv">
                <a href="#" className="navbar-brand headerLogo">
                    <img src={coyoteFavicon } width="45" alt="coyote favicon" />
                </a>
            </div>
        );
    }
}