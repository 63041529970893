import React, { Component } from 'react';
import { Header } from './Header';
import { Footer } from './Footer';
import "../stylesheets/homeStyle.css";

export class Home extends Component {
    static displayName = Home.name;

    render() {
        return (
            <div>
                <Header />
                <div className="homeDiv">
                    <p className="d-flex align-content-center justify-content-center mb-3 text-danger fw-bold">
                        Your token is either invalid or expired. This Rate Confirmation can no longer be viewed.
                    </p>
                    <p className="d-flex align-content-center justify-content-center mb-3 text-danger fw-bold">
                        Please contact your Coyote representative if this issue persists or if you believe this is incorrect.
                    </p>
                </div>
                <Footer className="homeFooter"/>
            </div>
        );
    }
}
