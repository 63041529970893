import React, { Component } from 'react';

export class Nudge extends Component {
    static displayName = Nudge.name;

    render() {
        return (
            <div>true</div>
        );
    }
}