import axios from "axios";

const config = {};
export default config;

const load = async () => {
    const { data } = await axios.get(`${process.env.PUBLIC_URL}/config.json`);

    for (let prop in config) {
        delete config[prop];
    }

    for (let prop in data) {
        config[prop] = data[prop];
    }

    return config;
};

export { load };